import React from "react";
import {useScreenDetails} from "../Context/ScreenContext";

const FooterSocialMedias = () => {
	const screenDetails = useScreenDetails();
	
	return (
		<>
			<span>
				<a href="https://www.instagram.com/archbranding.se?igsh=NHU3bzEwbTBlOXpm&amp;utm_source=qr"
				   className="social-n-link1" target="_blank" rel="noreferrer">
					{!screenDetails.isMobile
						?
							<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
								 className="svg-icon">
								<path
									d="M16.9482 0.0753853C14.7402 -0.0276129 9.27123 -0.0226129 7.06123 0.0753853C5.11923 0.166384 3.40623 0.635375 2.02523 2.01635C-0.282773 4.32431 0.0122273 7.43426 0.0122273 11.9952C0.0122273 16.6631 -0.247773 19.701 2.02523 21.974C4.34223 24.29 7.49723 23.987 12.0042 23.987C16.6282 23.987 18.2242 23.99 19.8592 23.357C22.0822 22.494 23.7602 20.507 23.9242 16.9381C24.0282 14.7291 24.0222 9.26122 23.9242 7.05126C23.7262 2.83834 21.4652 0.283382 16.9482 0.0753853ZM20.4432 20.447C18.9302 21.96 16.8312 21.825 11.9752 21.825C6.97523 21.825 4.97023 21.899 3.50723 20.432C1.82223 18.7551 2.12723 16.0621 2.12723 11.9792C2.12723 6.45427 1.56023 2.47534 7.10523 2.19135C8.37923 2.14635 8.75423 2.13135 11.9612 2.13135L12.0062 2.16135C17.3352 2.16135 21.5162 1.60336 21.7672 7.14726C21.8242 8.41224 21.8372 8.79223 21.8372 11.9942C21.8362 16.9361 21.9302 18.9531 20.4432 20.447Z"></path>
								<path
									d="M12.1432 5.83755C8.7402 5.83755 5.9852 8.59551 5.9852 11.9954C5.9852 15.3984 8.7432 18.1533 12.1432 18.1533C15.5462 18.1533 18.3012 15.3954 18.3012 11.9954C18.3012 8.59251 15.5432 5.83755 12.1432 5.83755ZM12.1432 15.9924C9.9342 15.9924 8.1462 14.2034 8.1462 11.9954C8.1462 9.78749 9.9352 7.99852 12.1432 7.99852C14.3512 7.99852 16.1402 9.78749 16.1402 11.9954C16.1412 14.2034 14.3522 15.9924 12.1432 15.9924Z"></path>
								<path
									d="M18.5451 7.03257C19.3398 7.03257 19.9841 6.38832 19.9841 5.5936C19.9841 4.79887 19.3398 4.15462 18.5451 4.15462C17.7504 4.15462 17.1061 4.79887 17.1061 5.5936C17.1061 6.38832 17.7504 7.03257 18.5451 7.03257Z"></path>
							</svg>
						:
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="svg-icon">
								<path
									d="M15.0926 4.54712C13.7126 4.48274 10.2945 4.48587 8.91327 4.54712C7.69952 4.60399 6.62889 4.89711 5.76577 5.76022C4.32327 7.20269 4.50764 9.14641 4.50764 11.997C4.50764 14.9144 4.34514 16.8132 5.76577 18.2338C7.21389 19.6812 9.18577 19.4919 12.0026 19.4919C14.8926 19.4919 15.8901 19.4937 16.912 19.0981C18.3014 18.5587 19.3501 17.3169 19.4526 15.0863C19.5176 13.7057 19.5139 10.2883 19.4526 8.90704C19.3289 6.27396 17.9158 4.67711 15.0926 4.54712ZM17.277 17.2794C16.3314 18.225 15.0195 18.1406 11.9845 18.1406C8.85952 18.1406 7.60639 18.1869 6.69202 17.27C5.63889 16.2219 5.82952 14.5388 5.82952 11.987C5.82952 8.53392 5.47514 6.04709 8.94077 5.86959C9.73702 5.84147 9.97139 5.83209 11.9758 5.83209L12.0039 5.85084C15.3345 5.85084 17.9476 5.5021 18.1045 8.96704C18.1401 9.75765 18.1483 9.99515 18.1483 11.9964C18.1476 15.0851 18.2064 16.3457 17.277 17.2794Z"
									fill="#A8A8A8"/>
								<path
									d="M12.0895 8.14847C9.96263 8.14847 8.24075 9.87219 8.24075 11.9972C8.24075 14.124 9.9645 15.8458 12.0895 15.8458C14.2164 15.8458 15.9383 14.1221 15.9383 11.9972C15.9383 9.87032 14.2145 8.14847 12.0895 8.14847ZM12.0895 14.4952C10.7089 14.4952 9.59137 13.3771 9.59137 11.9972C9.59137 10.6172 10.7095 9.49907 12.0895 9.49907C13.4695 9.49907 14.5876 10.6172 14.5876 11.9972C14.5883 13.3771 13.4701 14.4952 12.0895 14.4952Z"
									fill="#A8A8A8"/>
								<path
									d="M16.0907 8.89536C16.5874 8.89536 16.9901 8.4927 16.9901 7.996C16.9901 7.4993 16.5874 7.09664 16.0907 7.09664C15.594 7.09664 15.1913 7.4993 15.1913 7.996C15.1913 8.4927 15.594 8.89536 16.0907 8.89536Z"
									fill="#A8A8A8"/>
							</svg>
					}
				</a>
			</span>
			
			<span>
				<a href="https://www.facebook.com/share/Q1Rf5fABWqQZ1TBv/?mibextid=LQQJ4d"
				   target="_blank" rel="noreferrer">
					{!screenDetails.isMobile
						?
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
								 xmlns="http://www.w3.org/2000/svg" className="svg-icon">
								<g clip-path="url(#clip0_829_3)">
									<path
										d="M15.997 3.985H18.188V0.169C17.81 0.117 16.51 0 14.996 0C11.837 0 9.67301 1.987 9.67301 5.639V9H6.18701V13.266H9.67301V24H13.947V13.267H17.292L17.823 9.001H13.946V6.062C13.947 4.829 14.279 3.985 15.997 3.985Z"
										fill="#A8A8A8"/>
								</g>
								<defs>
									<clipPath id="clip0_829_3">
										<rect width="24" height="24" fill="#A8A8A8"/>
									</clipPath>
								</defs>
							</svg>
						:
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="svg-icon">
								<path
									d="M14.1313 7.49062H15.5006V5.10563C15.2644 5.07312 14.4519 5 13.5056 5C11.5313 5 10.1788 6.24187 10.1788 8.52437V10.625H8V13.2913H10.1788V20H12.85V13.2919H14.9406L15.2725 10.6256H12.8494V8.78875C12.85 8.01812 13.0575 7.49062 14.1313 7.49062Z"
									fill="#A8A8A8"/>
							</svg>
					}
				</a>
			</span>
			
			<span>
				<a href="https://www.pinterest.com/"
				   target="_blank" rel="noreferrer">
					{!screenDetails.isMobile
						?
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
								 xmlns="http://www.w3.org/2000/svg" className="svg-icon">
								<path
									d="M12.2683 0C5.56374 0.00099357 2 4.18889 2 8.75534C2 10.8726 3.21373 13.5145 5.15712 14.3521C5.7115 14.5955 5.63813 14.2985 6.11506 12.52C6.15277 12.3719 6.13341 12.2438 6.01112 12.1057C3.23309 8.97293 5.46896 2.53261 11.8719 2.53261C21.1384 2.53261 19.407 15.0337 13.4841 15.0337C11.9575 15.0337 10.8202 13.8653 11.1799 12.4196C11.6161 10.6978 12.4701 8.84675 12.4701 7.60578C12.4701 4.47802 7.69057 4.94202 7.69057 9.0862C7.69057 10.3669 8.15527 11.2313 8.15527 11.2313C8.15527 11.2313 6.61747 17.2881 6.33213 18.4198C5.84908 20.3354 6.39735 23.4363 6.44525 23.7036C6.4748 23.8507 6.64397 23.8974 6.73874 23.7761C6.89059 23.5824 8.74939 20.9971 9.27015 19.1282C9.4597 18.4476 10.2373 15.6855 10.2373 15.6855C10.7499 16.5877 12.2275 17.3428 13.802 17.3428C18.4857 17.3428 21.8711 13.3287 21.8711 8.34798C21.8548 3.57288 17.6633 0 12.2683 0Z"
									fill="#A8A8A8"/>
							</svg>
						:
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="svg-icon">
								<path
									d="M12.4593 5C8.24178 5.00063 6 7.63504 6 10.5076C6 11.8395 6.7635 13.5014 7.986 14.0283C8.33474 14.1814 8.28858 13.9945 8.58859 12.8757C8.61231 12.7826 8.60013 12.702 8.52321 12.6151C6.77568 10.6445 8.18216 6.59315 12.2099 6.59315C18.0391 6.59315 16.9499 14.457 13.2241 14.457C12.2638 14.457 11.5484 13.722 11.7747 12.8126C12.049 11.7295 12.5862 10.5651 12.5862 9.78444C12.5862 7.81691 9.57967 8.10879 9.57967 10.7157C9.57967 11.5213 9.87199 12.0651 9.87199 12.0651C9.87199 12.0651 8.90464 15.8752 8.72514 16.587C8.42128 17.7921 8.76617 19.7427 8.7963 19.9108C8.81489 20.0033 8.9213 20.0327 8.98092 19.9565C9.07644 19.8346 10.2457 18.2083 10.5733 17.0327C10.6925 16.6045 11.1817 14.867 11.1817 14.867C11.5041 15.4345 12.4337 15.9095 13.4241 15.9095C16.3704 15.9095 18.5 13.3845 18.5 10.2513C18.4897 7.24753 15.8531 5 12.4593 5Z"
									fill="#A8A8A8"/>
							</svg>
					}
				</a>
			</span>
			
			<span>
				<a href="https://www.behance.net/ArchBrandingStudio"
				   target="_blank" rel="noreferrer">
					{!screenDetails.isMobile
						?
							<svg width="24" height="26" viewBox="0 0 24 26" fill="none"
								 xmlns="http://www.w3.org/2000/svg" className="svg-icon">
								<path
									d="M12.363 15.7341C12.363 13.7889 11.484 12.351 9.668 11.812C10.993 11.1478 11.684 10.1415 11.684 8.57625C11.684 5.48994 9.492 4.7373 6.963 4.7373H0V20.2331H7.158C9.842 20.2331 12.363 18.8826 12.363 15.7341ZM3.246 7.38257H6.292C7.463 7.38257 8.517 7.72783 8.517 9.15309C8.517 10.4689 7.697 10.9984 6.537 10.9984H3.246V7.38257ZM3.245 17.6015V13.3341H6.783C8.212 13.3341 9.116 13.9594 9.116 15.5468C9.116 17.112 8.037 17.6015 6.716 17.6015H3.245Z"
									fill="#A8A8A8"/>
								<path
									d="M18.7962 20.5267C21.3502 20.5267 23.0042 19.3194 23.8002 16.7531H21.2082C20.9292 17.7109 19.7792 18.2173 18.8872 18.2173C17.1662 18.2173 16.2622 17.1594 16.2622 15.3615H23.9752C24.2192 11.7636 22.3152 8.69727 18.7952 8.69727C15.5362 8.69727 13.3242 11.2678 13.3242 14.6352C13.3242 18.1299 15.4202 20.5267 18.7962 20.5267ZM18.7042 11.0257C20.1792 11.0257 20.9212 11.9352 21.0452 13.4225H16.2662C16.3632 11.9478 17.2962 11.0257 18.7042 11.0257Z"
									fill="#A8A8A8"/>
								<path d="M15.667 5.55078H21.655V7.0771H15.667V5.55078Z" fill="#A8A8A8"/>
							</svg>
						:
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none" className="svg-icon">
								<path
									d="M12.2735 14.2487C12.2735 12.7896 11.6141 11.711 10.2519 11.3067C11.2458 10.8085 11.7641 10.0537 11.7641 8.87959C11.7641 6.56455 10.1199 6 8.22293 6H3V17.6234H8.3692C10.3825 17.6234 12.2735 16.6103 12.2735 14.2487ZM5.43482 7.98421H7.71962C8.59798 7.98421 9.38858 8.24319 9.38858 9.31227C9.38858 10.2992 8.7735 10.6964 7.90339 10.6964H5.43482V7.98421ZM5.43407 15.6494V12.4485H8.08791C9.1598 12.4485 9.83789 12.9175 9.83789 14.1082C9.83789 15.2823 9.02854 15.6494 8.03766 15.6494H5.43407Z"
									fill="#A8A8A8"/>
								<path
									d="M17.0997 17.844C19.0154 17.844 20.2561 16.9383 20.8531 15.0133H18.9089C18.6996 15.7318 17.837 16.1116 17.1679 16.1116C15.877 16.1116 15.1989 15.3181 15.1989 13.9695H20.9844C21.1674 11.2707 19.7392 8.9707 17.0989 8.9707C14.6543 8.9707 12.9951 10.8988 12.9951 13.4247C12.9951 16.0461 14.5673 17.844 17.0997 17.844ZM17.0306 10.7172C18.137 10.7172 18.6936 11.3994 18.7866 12.5151H15.2019C15.2747 11.4089 15.9745 10.7172 17.0306 10.7172Z"
									fill="#A8A8A8"/>
								<path d="M14.7529 6.60938H19.2445V7.75426H14.7529V6.60938Z" fill="#A8A8A8"/>
							</svg>
					}
				</a>
			</span>
			
			<span>
				<a href="https://www.linkedin.com/company/arch-branding/"
				   target="_blank" rel="noreferrer">
					 {!screenDetails.isMobile
						 ?
							 <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
								  xmlns="http://www.w3.org/2000/svg" className="svg-icon">
								 <g clip-path="url(#clip0_829_6)">
									 <path
										 d="M21.0886 21.8706L21.0932 21.8698V14.5821C21.0932 11.0169 20.3769 8.27051 16.4868 8.27051C14.6167 8.27051 13.3617 9.37004 12.8494 10.4124H12.7953V8.60335H9.10687V21.8698H12.9475V15.3007C12.9475 13.5711 13.2535 11.8986 15.2527 11.8986C17.2225 11.8986 17.2518 13.8725 17.2518 15.4117V21.8706H21.0886Z"
										 fill="#A8A8A8"/>
									 <path d="M2.85291 8.60449H6.69819V21.8709H2.85291V8.60449Z" fill="#A8A8A8"/>
									 <path
										 d="M4.77399 2C3.54452 2 2.54688 3.0689 2.54688 4.38619C2.54688 5.70348 3.54452 6.79474 4.77399 6.79474C6.00346 6.79474 7.0011 5.70348 7.0011 4.38619C7.00032 3.0689 6.00268 2 4.77399 2Z"
										 fill="#A8A8A8"/>
								 </g>
								 <defs>
									 <clipPath id="clip0_829_6">
										 <rect width="18.5464" height="19.8711" fill="#A8A8A8"
											   transform="translate(2.54688 2)"/>
									 </clipPath>
								 </defs>
							 </svg>
						 :
							 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="svg-icon">
								 <g clip-path="url(#clip0_901_295)">
									 <path
										 d="M19.1784 18.8854L19.1819 18.8848V13.3835C19.1819 10.6923 18.6411 8.61914 15.7046 8.61914C14.293 8.61914 13.3456 9.44914 12.9589 10.236H12.918V8.87039H10.1338V18.8848H13.033V13.926C13.033 12.6204 13.264 11.3579 14.773 11.3579C16.26 11.3579 16.2821 12.8479 16.2821 14.0098V18.8854H19.1784Z"
										 fill="#A8A8A8"/>
									 <path d="M5.23145 8.87109H8.13411V18.8855H5.23145V8.87109Z" fill="#A8A8A8"/>
									 <path
										 d="M6.68117 4C5.75308 4 5 4.80687 5 5.80125C5 6.79562 5.75308 7.61937 6.68117 7.61937C7.60925 7.61937 8.36233 6.79562 8.36233 5.80125C8.36175 4.80687 7.60867 4 6.68117 4Z"
										 fill="#A8A8A8"/>
								 </g>
								 <defs>
									 <clipPath id="clip0_901_295">
										 <rect width="14" height="15" fill="#A8A8A8" transform="translate(5 4)"/>
									 </clipPath>
								 </defs>
							 </svg>
					 }
				</a>
			</span>
		</>
	)
};

export default FooterSocialMedias;
