import { useState, useEffect, createContext, useContext } from 'react';

const ScreenContext = createContext({isMobile: false});

export const ScreenProvider = ({ children }) => {
	const [screenDetails, setScreenDetails] = useState({isMobile: false});
	useEffect(() => {
		const handleResize = () => {
			setScreenDetails({isMobile: window.innerWidth < 768});
		};
		
		handleResize();
		window.addEventListener('resize', handleResize);
		
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	
	return (
		<ScreenContext.Provider value={screenDetails}>
			{children}
		</ScreenContext.Provider>
	);
}

export const useScreenDetails = () => useContext(ScreenContext);
