import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {Row, Col} from "antd";
import '../assets/css/gallery.css';
import {NavLink} from "react-router-dom";
import {useScreenDetails} from "../Context/ScreenContext";

const PostList = ({ posts }) => {
	const screenDetails = useScreenDetails();
	
	return (
		<div className="gallery">
			<Row gutter={[34, !screenDetails.isMobile ? 80 : 50]}>
				{posts.map((post, index) => (
					<Col xs={24} sm={24} md={12} key={index}>
						<NavLink to={`/works/${post.uid}`} className="no-hover">
							<div className="gallery-item">
								<div className="image-wrapper">
									<LazyLoadImage
										src={post.src}
										width="100%"
										className="square-image"
									/>
								</div>
								<p className="caption" style={{paddingTop: 16}}>{post.caption}</p>
							</div>
						</NavLink>
					</Col>
				))}
			</Row>
		</div>
	);
};

PostList.propTypes = {
	posts: PropTypes.arrayOf(
		PropTypes.shape({
			src: PropTypes.string.isRequired,
			caption: PropTypes.string.isRequired,
		})
	).isRequired
}

export default PostList;
