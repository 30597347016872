import React, { useEffect, useState, useCallback } from "react";
import '../assets/css/works.css';
import axios from "axios";
import PostList from "../components/PostList";
import ArrowDown from "../components/ArrowDown";
import { useScreenDetails } from "../Context/ScreenContext";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonPostList from "../components/SkeletonPostList";

const Works = () => {
	const [activeCategory, setActiveCategory] = useState('all');
	const [posts, setPosts] = useState(new Map());
	const [page, setPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [transition, setTransition] = useState(false);
	const screenDetails = useScreenDetails();
	const location = useLocation();
	const navigate = useNavigate();
	
	const displayWorks = useCallback(async (category, page) => {
		try {
			setIsLoading(true);
			const response = await axios.get(
				`${process.env.REACT_APP_NODE_APP_API_RUL}/app/works/${category}/${page}`,
				{
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
				}
			);
			
			if (response.status === 200 && response.data.posts) {
				const newPosts = response.data.posts.map(post => ({
					uid: post._id,
					caption: post.title,
					src: post.thumbNail.filePath,
				}));
				
				setPosts(prevPosts => {
					const updatedPosts = new Map(prevPosts);
					if (!updatedPosts.has(category)) {
						updatedPosts.set(category, newPosts);
					} else {
						const existingPosts = updatedPosts.get(category);
						updatedPosts.set(category, [...existingPosts, ...newPosts.filter(post => !existingPosts.some(existingPost => existingPost.uid === post.uid))]);
					}
					return updatedPosts;
				});
			}
			setIsLoading(false);
			setTransition(false);
		} catch (ex) {
			console.error(ex);
			setIsLoading(false);
			setTransition(false);
		}
	}, []);
	
	useEffect(() => {
		const categoryFromURL = location.hash.replace('#', '') || 'all';
		setTransition(true);  // Trigger fade out before fetching posts
		setTimeout(() => {
			setActiveCategory(categoryFromURL);
			displayWorks(categoryFromURL, page);
		}, 500);
	}, [location.hash, page, displayWorks]);
	
	const handleCategoryClick = (category) => {
		setPage(1);
		navigate(`/works#${category}`);
	};
	
	const handleLoadMoreClick = () => {
		setPage(prevPage => prevPage + 1);
	};
	
	return (
		<div>
			<div>
				<div className="works-title">
					{activeCategory === 'projects_on_sale'
						?
						<p>Our creative and bold concepts are crafted for visionaries ready to bring them to life. Each unique creation is available for you to elevate and make your own.</p>
						: <p>Explore our purpose-driven projects, where design aligns with intent to create lasting connections between brands and their communities.</p>
					}
				</div>
				
				<div className="works-title-m">
					<span>Selected Works</span>
				</div>
				
				<div className="work-categories">
					<div>
						{['all', 'branding', 'rebranding'].map(category => (
							<button
								key={category}
								className={`category-button ${activeCategory === category ? 'active' : ''}`}
								onClick={() => handleCategoryClick(category)}
							>
								{category.replaceAll('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}
							</button>
						))}
					</div>
					<div>
						{['packaging_design', 'projects_on_sale'].map(category => (
							<button
								key={category}
								className={`category-button ${activeCategory === category ? 'active' : ''}`}
								onClick={() => handleCategoryClick(category)}
							>
								{category.replaceAll('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}
							</button>
						))}
					</div>
				</div>
			</div>
			
			<div className={transition ? 'fade-out' : 'fade-in'}>
				{(!posts.size || isLoading) &&
					<div style={screenDetails.isMobile ? {paddingBottom: 40} : {paddingBottom: 60}}>
						<SkeletonPostList itemsCount={4} />
					</div>
				}
				{posts.has(activeCategory) &&
					<div style={screenDetails.isMobile ? {paddingBottom: 40} : {paddingBottom: 60}}>
						<PostList posts={posts.get(activeCategory)} />
						{posts.size && posts.size >= 8 &&
							<div className="load-works-section items-center font-silkaMedium">
								<a className="load-more flex items-center" style={{gap: 16}} alt="load more" onClick={handleLoadMoreClick}>
									<span>Load more works</span>
									<ArrowDown />
								</a>
							</div>
						}
					</div>
				}
			</div>
		</div>
	);
};

export default Works;
