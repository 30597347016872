import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../pages/Home";
import Header from "../components/Header";
import Footer from "../components/Footer";
import '../assets/css/global.css';
import {ScreenProvider, useScreenDetails} from "../Context/ScreenContext";
import Works from "../pages/Works";
import About from "../pages/About";
import Contact from "../pages/Contact";
import WorkDetails from "../pages/WorkDetails";

const AppRoutes = () => {
	const [isVisible, setIsVisible] = useState(false);
	const location = useLocation();
	const isHomePage = location.pathname === '/';
	const screenDetails = useScreenDetails();
	
	// useEffect(() => {
	// 	setIsVisible(false);
	// 	const timer = setTimeout(() => {
	// 		setIsVisible(true);
	// 	}, 400);
	//
	// 	return () => clearTimeout(timer);
	// }, [location.pathname]);
	
	return (
		<ScreenProvider>
			<div className="flex flex-col " style={{ minHeight: '100vh' }}>
				<Header />
				<main className="flex-grow main-t-styles" style={!isHomePage && !screenDetails.isMobile ? { paddingTop: 50, minHeight: '100vh' } : {}}>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/works" element={<Works />} />
						<Route path="/works/:workId" element={<WorkDetails />} />
						<Route path="/about" element={<About />} />
						<Route path="/contact" element={<Contact />} />
					</Routes>
				</main>
				<Footer />
			</div>
		</ScreenProvider>
	);
};

export default AppRoutes;
