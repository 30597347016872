import React, { useEffect, useRef, useCallback, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PostList from '../components/PostList';
import '../assets/css/work-details.css';
import Player from '@vimeo/player';
import ArrowUp from "../components/ArrowUp";
import { useScreenDetails } from "../Context/ScreenContext";
import Skeleton from "react-loading-skeleton";

const debounce = (func, wait) => {
	let timeout;
	return (...args) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(this, args), wait);
	};
};

const WorkDetails = () => {
	const { workId } = useParams();
	const [post, setPost] = useState(null);
	const [latestPosts, setLatestPosts] = useState([]);
	const iframeRefs = useRef([]);
	const screenDetails = useScreenDetails();
	const videoReady = useRef({}); // Use useRef to manage video readiness state
	
	const [isLoading, setIsLoading] = useState(true);
	
	const getEmbedUrl = (url) => {
		if (url.includes('youtube.com')) {
			const videoId = url.split('v=')[1];
			return `https://www.youtube.com/embed/${videoId}?enablejsapi=1&controls=1&modestbranding=1&showinfo=0&rel=0&loop=1&autoplay=1&mute=1&iv_load_policy=3`;
		} else if (url.includes('vimeo.com')) {
			const videoId = url.split('.com/')[1];
			return `https://player.vimeo.com/video/${videoId}?api=1&title=0&byline=0&portrait=0&autoplay=1&muted=1&loop=1&dnt=1&controls=1`;
		}
		return url;
	};
	
	const initializeYouTubeAPI = useCallback(() => {
		iframeRefs.current.forEach((iframe, index) => {
			if (iframe && iframe.src.includes('youtube.com')) {
				try {
					new window.YT.Player(iframe, {
						events: {
							onReady: (event) => {
								if (event.target && !iframe.dataset.initialized) {
									event.target.stopVideo();
									iframe.dataset.initialized = true;
									videoReady.current[index] = true; // Set the videoReady flag
								}
							},
						},
					});
				} catch (e) {
					console.error('Error initializing YouTube API:', e);
				}
			}
		});
	}, []);
	
	const loadYouTubeIframeAPI = useCallback(() => {
		if (!window.YT) {
			window.onYouTubeIframeAPIReady = initializeYouTubeAPI;
			const tag = document.createElement('script');
			tag.src = 'https://www.youtube.com/iframe_api';
			const firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		} else {
			initializeYouTubeAPI();
		}
	}, [initializeYouTubeAPI]);
	
	useEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 500);
	}, []);
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(`${process.env.REACT_APP_NODE_APP_API_RUL}/app/works/${workId}`);
				setPost(response.data.mainPost);
				
				setLatestPosts(response.data.latestPosts.map((post) => ({
					uid: post._id,
					caption: post.title,
					src: post.mainImage.filePath,
				})));
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setIsLoading(false);
			}
		};
		
		setTimeout(() => {
			fetchData();
		}, 500);
		
	}, [workId]);
	
	useEffect(() => {
		try {
			const vimeoPlayers = {};
			
			const handleIntersection = debounce((entries) => {
				entries.forEach((entry) => {
					const iframe = entry.target;
					const index = iframe.dataset.index;
					
					if (!videoReady.current[index]) return;
					
					if (entry.isIntersecting) {
						if (iframe.src.includes('youtube.com')) {
							iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
						} else if (iframe.src.includes('vimeo.com')) {
							vimeoPlayers[index]?.play();
						}
					} else {
						if (iframe.src.includes('youtube.com')) {
							iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
						} else if (iframe.src.includes('vimeo.com')) {
							vimeoPlayers[index]?.pause();
						}
					}
				});
			}, 300);
			
			const observer = new IntersectionObserver(handleIntersection, {
				threshold: 0.1,
			});
			
			iframeRefs.current.forEach((iframe, index) => {
				iframe.dataset.index = index;
				if (iframe) {
					observer.observe(iframe);
					
					if (iframe.src.includes('vimeo.com')) {
						vimeoPlayers[index] = new Player(iframe);
						vimeoPlayers[index].on('loaded', () => {
							videoReady.current[index] = true;
						});
					} else if (iframe.src.includes('youtube.com')) {
						iframe.contentWindow.postMessage('{"event":"listening","id":' + index + '}', '*');
					}
				}
			});
			
			loadYouTubeIframeAPI();
			return () => {
				iframeRefs.current.forEach((iframe) => {
					if (iframe) observer.unobserve(iframe);
				});
			};
		} catch (e){}
		
	}, [loadYouTubeIframeAPI, post]);

	
	return (
		<div className={`fade-in-w ${!isLoading ? 'visible' : ''}`}>
				<div className="font-silka">
					<div className="d-title-s">
						<span className="d-title font-silkaMedium">{post && post.title}</span>
					</div>
					
					<div style={{ paddingBottom: screenDetails.isMobile ? 20 : 70 }}>
						{
							!isLoading ? (
								<LazyLoadImage
									alt="About us"
									src={post.mainImage.filePath}
									className="main-image"
								/>
							) : (
								<Skeleton className="main-image" style={{height: '70vh'}}/>
							)
						}
						
					</div>
					
					<div className="work-details-section">
						<div className="flex-1">
							{
								post && post['postType'] && post['postType'].includes('projects_on_sale')
									? (
										<>
											<div className="pt-5 wd-txt-t2">
												<span>Category: {post.category}</span>
											</div>
											
											<div className="pt-5 wd-txt-t2" style={{width: '50%'}}>
												<span>Service: {post.service}</span>
											</div>
											
											<div className="pt-5"><span></span></div>
											
											<div className="pt-5">
												<NavLink to="/contact"
														 onClick={() => window.scrollTo(0, 0)}
														 activeClassName="underline"
														 className="text-black contact-link"
												>This project is available<br/>for purchase.<br/>For more
													information,<br/>please contact us!<br/></NavLink>
											</div>
										</>
									)
									: (
										<>
											<div>
												<span className="wd-txt-t2">Client: {post && post.client}</span>
											</div>
											
											<div className="pt-5 wd-txt-t2 ">
												<span>Category: {post && post.category}</span>
											</div>
											
											<div className="pt-5 wd-txt-t2">
												<span>Country: {post && post.country}</span>
											</div>
											
											<div className="pt-5 wd-txt-t2" style={{width: '50%'}}>
												<span>Service: {post && post.service}</span>
											</div>
										</>
									)
							}
					
						</div>
						
						<div className="flex-1">
							<div className="description-inner-section">
								<span className="wd-txt-t1">{post && post.description}</span>
							</div>
						</div>
					</div>
					
					{!post &&
						<>
							<div style={{ paddingBottom: !screenDetails.isMobile ? 27 : 6 }}>
								<Skeleton width="100%" style={{ objectFit: 'contain', height: '100vh' }} />
							</div>
						</>
					}
					
					{post && post.inputsForMedia.length > 0 && post.inputsForMedia.map((item, index) => (
						<div key={item.id} style={post.inputsForMedia.length - 1 !== index ? { paddingBottom: !screenDetails.isMobile ? 27 : 6 } : {}}>
							{item.type === 'file' ? (
								<LazyLoadImage
									alt="About us"
									src={item.value}
									width="100%"
									style={{ objectFit: 'contain' }}
								/>
							) : (
								<iframe
									ref={(el) => (iframeRefs.current[index] = el)}
									src={getEmbedUrl(item.value)}
									width="100%"
									height="100%"
									style={{ border: 'none', aspectRatio: '16/9' }}
									allow="autoplay; fullscreen"
									allowFullScreen
								/>
							)}
						</div>
					))}
					
					<div>
						<div style={{ paddingBottom: screenDetails.isMobile ? 40 : 100, paddingTop: screenDetails.isMobile ? 40 : 100 }}>
							<div className="flex items-center justify-end" style={{ gap: 16 }}>
								<NavLink to='/works' onClick={() => window.scrollTo(0, 0)} className="text-black hover:underline active:underline flex justify-end">
									<div className="flex items-center" style={{ gap: 16 }}>
										<div>
											<span className="more-works-txt font-silkaMedium">More works</span>
										</div>
										<div>
											<ArrowUp />
										</div>
									</div>
								</NavLink>
							</div>
						</div>
						
						<div style={{ paddingBottom: screenDetails.isMobile ? 50 : 100 }}>
							<PostList posts={latestPosts} />
						</div>
					</div>
				</div>
			
		</div>
	);
};

export default WorkDetails;
