import React from "react";
import arrowUp from '../assets/svg/arrow-up.svg'

const ArrowUp = () => {
	return (
		<>
			<img className="arrow-up" src={arrowUp} alt=""/>
		</>
	);
};

export default ArrowUp;
