import React from "react";
import { Select } from "antd";
import countries from '../json/countries.json';
import '../assets/css/countrySelectInput.css'

const CountriesSelectInput = ({ value, onChange, placeholder= '' }) => {
	return (
		<>
			<Select
				showSearch
				placeholder={placeholder}
				options={countries}
				style={{ width: '100%', borderBottom: '1px solid black !important', paddingBottom: '5px' }}
				value={value || undefined}
				title="Country"
				onChange={onChange} />
		</>
	);
};

export default CountriesSelectInput;
