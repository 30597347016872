import {useScreenDetails} from "../Context/ScreenContext";
import {Col, Row} from "antd";
import React from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonPostList = ({ itemsCount }) => {
	const screenDetails = useScreenDetails();
	const skeletonItems = [];
	
	for (let i = 0; i < itemsCount; ++i) {
		skeletonItems.push(
			<Col xs={24} sm={24} md={12} key={i} >
				<div className="no-hover">
					<div className="gallery-item">
						<div className="image-wrapper">
							<Skeleton className="square-image"  />
						</div>
						<div style={{ width: '30%' }}>
							<Skeleton />
						</div>
					</div>
				</div>
			</Col>
		)
	}
	
	return (
		<div className="gallery">
			<Row gutter={[34, !screenDetails.isMobile ? 80 : 50]}>
				{ skeletonItems }
			</Row>
		</div>
	);
}

export default SkeletonPostList;

