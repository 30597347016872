import React, {useEffect, useState} from 'react';
import CountriesSelectInput from "./CountriesSelectInput";
import '../assets/css/contact-form.css'

const ContactForm = ({ onSubmit }) => {
	const [validForm, setValidForm] = useState(false);
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		country: '',
		projectDetails: '',
	});
	
	const [errors, setErrors] = useState({});
	
	const validateEmail = (email) => {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(String(email).toLowerCase());
	};
	
	const validate = (withErrors = true) => {
		let formErrors = {};
		if (!formData.firstName) formErrors.firstName = 'First name is required';
		if (!formData.lastName) formErrors.lastName = 'Last name is required';
		if (!formData.email) {
			formErrors.email = 'Email is required';
		} else if (!validateEmail(formData.email)) {
			formErrors.email = 'Email address is invalid';
		}
		if (!formData.country) formErrors.country = 'Country is required';
		if (!formData.projectDetails) formErrors.projectDetails = 'Message is required';
		
		if (!withErrors) {
			return Object.keys(formErrors).length === 0;
		}
		setErrors(formErrors);
		return Object.keys(formErrors).length === 0;
	};
	
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
		
		let formErrors = { ...errors };
		if (name === 'email') {
			if (!validateEmail(value)) {
				formErrors.email = 'Email address is invalid';
			} else {
				delete formErrors.email;
			}
		}
		setErrors(formErrors);
	};
	
	const handleCountryChange = (value) => {
		setFormData({ ...formData, country: value });
	};
	
	const handleSubmit = (e) => {
		e.preventDefault();
		if (validate()) {
			onSubmit(formData);
		}
	};
	
	useEffect(() => {
		if (validate(false)) {
			setValidForm(true);
		} else {
			setValidForm(false)
		}
	}, [ formData ]);
	
	return (
		<div className="c-form-root">
			<form className="c-us-form" onSubmit={handleSubmit}>
				<div className="contact-form-fs" style={{display: 'flex', justifyContent: 'space-between'}}>
					<div style={{flex: '0 0 48%'}}>
						<label style={{display: 'block', marginBottom: '8px'}}>First name*</label>
						<input
							type="text"
							name="firstName"
							value={formData.firstName}
							onChange={handleChange}
							style={{width: '100%', borderBottom: '1px solid black', paddingBottom: '5px'}}/>
						{errors.firstName && <span style={{color: 'red'}}>{errors.firstName}</span>}
					</div>
					
					<div style={{flex: '0 0 48%'}}>
						<label style={{display: 'block', marginBottom: '8px'}}>Last name*</label>
						<input type="text"
							   name="lastName"
							   value={formData.lastName}
							   onChange={handleChange}
							   style={{width: '100%', borderBottom: '1px solid black', paddingBottom: '5px'}}/>
						{errors.lastName && <span style={{color: 'red'}}>{errors.lastName}</span>}
					</div>
				</div>
				
				<div></div>
				
				<div className="c-form-s-section">
					<label style={{display: 'block', marginBottom: '8px'}}>Email*</label>
					<input type="email"
						   name="email"
						   value={formData.email}
						   onChange={handleChange}
						   style={{width: '100%', borderBottom: '1px solid black', paddingBottom: '5px'}}/>
					{errors.email && <span style={{color: 'red'}}>{errors.email}</span>}
				</div>
				
				<div className="c-from-t-section">
					<label style={{display: 'block', marginBottom: '8px'}}>Country*</label>
					<CountriesSelectInput onChange={handleCountryChange} value={formData.country}/>
					{/*<input type="text" style={{ width: '100%', borderBottom: '1px solid black', paddingBottom: '5px' }} />*/}
					{errors.country && <span style={{color: 'red'}}>{errors.country}</span>}
				</div>
				
				<div className="project-details-s">
					<label style={{display: 'block', marginBottom: '8px'}}>Message*</label>
					<textarea
						name="projectDetails"
						value={formData.projectDetails}
						onChange={handleChange}
						style={{width: '100%', borderBottom: '1px solid black', paddingBottom: '5px'}} rows="2"/>
					{errors.projectDetails && <span style={{color: 'red'}}>{errors.projectDetails}</span>}
				</div>
				
				<div style={{display: 'flex', justifyContent: 'flex-end'}}>
					<button type="submit" className={validForm ? 'submit-btn' : 'submit-btn disabled-btn'}>
						<span style={{marginRight: '8px'}}>SUBMIT</span>
					</button>
				</div>
			
			
			</form>
		</div>
	);
};

export default ContactForm;
