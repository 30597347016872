import React, {useEffect, useState} from "react";
import axios from "axios";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {NavLink, useLocation} from "react-router-dom";
import '../assets/css/about.css';
import {useScreenDetails} from "../Context/ScreenContext";
import Skeleton from "react-loading-skeleton";
import {Collapse} from "antd";
const { Panel } = Collapse;

const About = () => {
	const [imagePath, setImagePath] = useState(null);
	const location = useLocation();
	const screenDetails = useScreenDetails();
	
	const CustomArrow = ({ isActive }) => {
		return (
			<svg width="37" height="22" viewBox="0 0 37 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={`custom-arrow ${isActive ? 'rotate' : ''}`}>
				<path d="M35.5876 19.6171L18.9706 3.00014L2 19.9707" stroke="black" stroke-width="4"/>
			</svg>
		);
	};
	
	const displayImage = async () => {
		const response = await axios.get(
			`${process.env.REACT_APP_NODE_APP_API_RUL}/app/about-us`,
			{
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
			}
		);
		
		setImagePath(response.data.imagePath)
	};
	
	useEffect(() => {
		displayImage()
	}, []);
	
	useEffect(() => {
		if (location.hash) {
			const element = document.getElementById(location.hash.substring(1));
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
			
		}
	}, [location]);
	
	return (
		<div className="font-silka about-us-t-section" >
			<div className="au-first-s">
				<div>
					<p className="font-silkaMedium">Where every detail matters and every design tells a story</p>
				</div>
				
				<div>
					<span className="af-2-txt">At Arch, we're on a mission to bridge brands and consumers through purposeful design. With our expertise in design and visual elements, we craft captivating narratives that evoke the perfect emotions, transforming products into timeless icons. But our impact goes beyond aesthetics; it influences behavior, shapes perceptions, and drives positive change. We firmly believe that every design decision should be purposeful, smart, and sustainable.</span>
				</div>
			</div>
			
			<hr/>
			
			<div className="au-second-s">
				<div className="flex-1">
					<p style={screenDetails.isMobile ? {} : {paddingRight: 40}}>As a team of passionate individuals from diverse corners of the globe, we're united by a core belief: everything we create must serve a purpose and make a lasting impression. We don't just design for the sake of it – we meticulously consider the impact of every element, ensuring each project resonates deeply with all stakeholders involved. With bases in both Yerevan and Stockholm, we're spreading our culturally inspired handstyle to the world.</p>
				</div>
				
				<div className="flex-1">
					{imagePath
						? (<LazyLoadImage
							alt="Main Image"
							src={imagePath}
							width="100%"
							style={{maxHeight: 700, objectFit: "cover"}}
						/>)
						: (<Skeleton width="100%" style={{maxHeight: 700, height: '100%'}} alt="Main Image" />)
					}
				</div>
			</div>
			
			<hr/>
			
			<div className="au-third-s">
				<div className="flex-1">
					<span className="title-unleash-c font-silkaMedium">Unleashing Creativity</span>
				</div>
				
				
				<div className="flex-1">
					<div className="uc-section">
						<span>At Arch, we foster an open and innovative environment where our team can unleash their imaginations and bring their boldest ideas to life. We encourage creativity to flow freely. Once an idea is born, we ensure it finds the perfect owner – someone who shares our vision and passion.</span>
					</div>
					
					<NavLink to='/works#projects_on_sale'
							 onClick={() => window.scrollTo(0, 0)}
							 state='projects_on_sale'
							 className="explore-more text-black hover:underline active:underline">
						<div style={{textDecoration: "underline"}}>
							<p className="em-s" style={{gap: 40}}><span className="em-s font-silkaMedium">Explore our "for sale brands" and witness creativity in action.</span></p>
						</div>
					</NavLink>
				
				</div>
			</div>
			
			<hr/>
			
			<div id="our-services">
				<div className="flex-1 os-f-section">
					<span className="title-our-services font-silkaMedium">Branding & Packaging Services</span>
				</div>
				
				<div className="flex-1" style={{lineHeight: 1.6, fontSize: 30}}>
					<Collapse
						ghost
						accordion
						expandIconPosition="right"
						expandIcon={({ isActive }) => <CustomArrow isActive={isActive} />}
						className="custom-collapse"
					>
						<Panel header="Brand Consulting" key="1">
							<p>We collaborate closely with our clients to provide strategic advice and purposeful insights on realizing their brand vision. Our tailored approach ensures that every idea is executed efficiently, meeting expectations at every step.</p>
						</Panel>
						<Panel header="Insightful Research" key="2">
							<p>Our process begins with in-depth research to understand your brand, industry, and competition. This knowledge forms the foundation for all our work, guiding us in crafting strategies that position your brand effectively in the market.</p>
						</Panel>
						<Panel header="Naming" key="3">
							<p>A brand’s name is its first connection with the world. We apply a thoughtful, methodical approach to ensure your brand, product, or service name and slogan not only resonate with your audience but also stand out. We also assist with registration to protect your brand’s identity.</p>
						</Panel>
						<Panel header="Brand Strategy" key="4">
							<p>A purposeful brand begins with a strong platform. We develop a clear and strategic roadmap that aligns your brand with its core values and goals. This foundation guides consistent and resonant activities across all touchpoints, ensuring your brand's long-term growth and success.</p>
						</Panel>
						<Panel header="Brand Identity" key="5">
							<p>Your brand identity is the visual and emotional core of who you are. We design enduring and adaptable identities that clearly communicate your brand’s values, ensuring it remains relevant and recognizable as it grows.</p>
						</Panel>
						<Panel header="Brand Framework" key="6">
							<p>We develop clear design frameworks that ensure your brand’s visual identity is consistently applied. Our guidelines simplify the creation of cohesive and impactful materials, reinforcing your brand’s presence across all touch points.</p>
						</Panel>
						<Panel header="Packaging Design" key="7">
							<p>Packaging is a key touch point where your brand connects directly with consumers. We create impactful, sustainable packaging solutions that reflect your brand’s identity and appeal to your audience.</p>
						</Panel>
						<Panel header="Brand Implementation" key="8">
							<p>Bringing your brand to life requires precision and consistency. We ensure your brand is seamlessly applied across all platforms, refining its presentation to maximize impact and resonate with your audience.</p>
						</Panel>
						
					</Collapse>
				</div>
			</div>
		</div>
	);
};

export default About;
