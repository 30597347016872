import React from "react";
import ContactForm from "../components/ContactForm";
import axios from "axios";
import '../assets/css/contact.css';
import {useScreenDetails} from "../Context/ScreenContext";

const Contact = () => {
	const [formData, setFormData] = React.useState({})
	const [emailSent, setEmailSent] = React.useState(false);
	const [failedToSend, setFailedToSend] = React.useState(false);
	const screenDetails = useScreenDetails();
	
	const handleFormSubmit = async (data) => {
		setFormData(data);
		const response = await axios.post(
			`${process.env.REACT_APP_NODE_APP_API_RUL}/app/contact-us`,
			JSON.stringify({formData: data}),
			{
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
			}
		);
		
		if (response.status === 200) {
			setEmailSent(true);
			setFailedToSend(false);
		} else {
			setFailedToSend(true);
		}
	};
	
	return (
		<div className="font-silka">
			<div className="c-top-title font-silkaMedium"><span>We’d love to meet you and hear what you want to create. Drop us a line or give us a call!</span>
			</div>
			
			<hr/>
			
			<div className="cm-section">
				<div className="title-cm-contact">
					<div>
						<span className="title-cm-contact font-silkaMedium">Contact</span>
					</div>
					<div>
						{!emailSent && <p className="cm-txt-2">For business inquiries, please reach out to us by filling out the form.</p>}
					</div>
				</div>
				
				<div className="flex-1 form-section">
					<div>
						{!emailSent
							? <ContactForm onSubmit={handleFormSubmit}/>
							: <div style={{fontWeight: 500, fontSize: 30, display: "flex", flexDirection: "column", textAlign: "center", verticalAlign: "middle", justifyContent: "center", minHeight: 450}}>
								<h2>Thanks for your message!</h2>
								<p>We’ve received it and will get back to you soon.</p>
						      </div>
						}
						{ failedToSend && <div> <span style={{color: "red"}}>Failed to send email, please try again later.</span> </div>}
					</div>
				</div>
			
			</div>
			
			<hr/>
			
			<div className="c-bottom-section">
				<div style={{fontWeight: 400, fontSize: 47, flex: 1}}>
					<span className="cb-txt-1 font-silkaMedium">Join the Arch team</span>
				</div>
				
				<div className="flex-1 cb-txt-2" style={{paddingBottom: 100}}>
					{screenDetails.isMobile
						? <>
							<p>Are you a creative mind with bold ideas? We're excited to explore the possibilities of collaboration with you.</p>
							
							<br/>
							
							<p>Please connect with us by sending an email to info@archbranding.se</p>
						</>
						: <>
							<p>Are you a creative mind with bold ideas?<br/>
								We're excited to explore the possibilities of<br/>
								collaboration with you.<br/>
							</p>
							
							<br/>
							
							<p>Please connect with us by sending an email to info@archbranding.se</p>
						</>
					}
				
				</div>
			
			
			</div>
		
		</div>
	);
};

export default Contact;
