import React from "react";
import arrowDown from '../assets/svg/arrow-down.svg'

const ArrowDown = () => {
	return (
		<>
			<img className="arrow-down" src={arrowDown} alt=""/>
		</>
	);
};

export default ArrowDown;
