import React from "react";
import {NavLink} from "react-router-dom";
import '../assets/css/header.css';
import closeMenu from '../assets/svg/close-menu.svg';

export default function Header() {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
	const openedMobileStyles = {
		height: '100vh',
		'flexDirection': 'column',
	}
	
	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
		if (!isMobileMenuOpen) {
			document.getElementsByTagName('body')[0].style.overflowY = "hidden";
			document.body.style.overflow = "hidden";
			document.body.style.height = "100%";
		} else {
			document.getElementsByTagName('body')[0].style.overflowY = "auto";
			document.body.style.overflow = "auto";
			document.body.style.height = "auto";
		}

	};
	
	return (
		<header className="header-root" style={isMobileMenuOpen ? openedMobileStyles : {}} >
			<div className="d-menue flex justify-between items-center w-full">
				<nav className="flex justify-between w-full">
					<NavLink to="/" exact
							 className={({isActive}) => `arch-home text-black hover:underline ${isActive ? "link-active" : "link"}`}>
						<span className="font-silka header-links">arch</span>
					</NavLink>
					<NavLink to="/works"
							 className={({isActive}) => `text-black hover:underline ${isActive ? "link-active" : "link"}`}>
						<span className="font-silka header-links">work</span>
					</NavLink>
					<NavLink to="/about"
							 className={({isActive}) => `text-black hover:underline ${isActive ? "link-active" : "link"}`}>
						<span className="font-silka header-links">about</span>
					</NavLink>
					<NavLink to="/contact"
							 className={({isActive}) => `text-black hover:underline ${isActive ? "link-active" : "link"}`}>
						<span className="font-silka header-links">contact</span>
					</NavLink>
				</nav>
			
			</div>
			
			<div className="m-menu">
				<div>
					<NavLink to="/" exact activeClassName="underline" className="arch-home text-black">
						<span className="font-silka">arch</span>
					</NavLink>
				</div>
				<div className="b-menue">
					{isMobileMenuOpen
						? <button onClick={toggleMobileMenu}><img className="closeMenuImg" src={closeMenu} alt="Close menu"/></button>
						: <button onClick={toggleMobileMenu}>
							<svg xmlns="http://www.w3.org/2000/svg" width="41" height="25" viewBox="0 0 41 25"
								 fill="none">
								<rect x="0.210938" width="40.7895" height="3.94737" fill="black"/>
								<rect x="0.210938" y="10.5264" width="40.7895" height="3.94737" fill="black"/>
								<rect x="0.210938" y="21.0527" width="40.7895" height="3.94737" fill="black"/>
							</svg>
						</button>
					}
				
				</div>
			</div>
			{isMobileMenuOpen && (
				<div className="mobile-menu-section flex flex-col md:hidden">
					<NavLink to="/works" className={({isActive}) => `text-black hover:underline ${isActive ? "link-active" : "link"}`} onClick={toggleMobileMenu}>
						<span className="font-silka header-links">work</span>
					</NavLink>
					<NavLink to="/about" className={({isActive}) => `text-black hover:underline ${isActive ? "link-active" : "link"}`} onClick={toggleMobileMenu}>
						<span className="font-silka header-links">about</span>
					</NavLink>
					<NavLink to="/contact" className={({isActive}) => `text-black hover:underline ${isActive ? "link-active" : "link"}`} onClick={toggleMobileMenu}>
						<span className="font-silka header-links">contact</span>
					</NavLink>
				</div>
			)}
		</header>
	);
}
