import React from 'react';
import '../assets/css/footer.css';
import {NavLink} from "react-router-dom";
import FooterSocialMedias from "./FooterSocialMedias";
import useScrollToTop from "../hooks/scrollToTop";

const Footer = () => {
	const currentYear = new Date().getFullYear();
	
	useScrollToTop();
	
	const handleLinkClick = () => {
		window.scrollTo(0, 0);
	};
	
	return (
		<div id="footer">
			<div className="footer-container">
				<div className="top-layer font-silka">
					<div className="ft-info">
						<p>info@archbranding.se</p>
						<p>+(374) 44 60 13 73</p>
						<p>+(46) 761 60 13 73</p>
					</div>
					
					<div className="ft-links">
						<NavLink to="/about" onClick={handleLinkClick} activeClassName="underline" className="text-black hover:underline active:underline">about us</NavLink>
						<NavLink to="/about#our-services" activeClassName="underline" className="text-black hover:underline active:underline">services</NavLink>
						<NavLink to="/works" onClick={handleLinkClick} activeClassName="underline" className="text-black hover:underline active:underline">work</NavLink>
						<NavLink to="/contact" onClick={handleLinkClick} activeClassName="underline" className="text-black hover:underline active:underline">contact</NavLink>
					</div>
				</div>
				
				<div className="bottom-layer font-silka">
					<div>
						<span className="a-reserved">all rights reserved {currentYear}</span>
					</div>
					
					<div>
						<FooterSocialMedias />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
