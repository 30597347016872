import React, {useEffect, useState} from 'react';
import { Carousel }	from 'antd';
import axios from "axios";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PostList from "../components/PostList";
import {NavLink} from "react-router-dom";
import '../assets/css/home.css';
import ArrowUp from "../components/ArrowUp";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import SkeletonPostList from "../components/SkeletonPostList";


const contentStyle = {
	margin: 0,
	height: 'calc(100vh - 153px)',
	color: '#fff',
	lineHeight: '160px',
	textAlign: 'center',
	objectFit: 'cover'
};

const Home = () => {
	const [carouselImages, setCarouselImages] = useState([]);
	const [galleryImages, setGalleryImages] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [transition, setTransition] = useState(false);
	
	const displayCarouselImages = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_NODE_APP_API_RUL}/content-management/home`,
				{
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
				}
			);
			if (response.status === 200) {
				
				if (!response.data.images) {
					return;
				}
				
				setCarouselImages(response.data.images.map((image, index) => ({
					uid: image._id,
					name: `Image ${index + 1}`,
					url: image.imagePath,
					status: 'done'
				})))
			}
			
			
		} catch (ex) {} finally {
			setIsLoading(false); // Loading completed
			setTransition(false); // Transition completed
		}
	}
	
	const displayGalleryImages = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_NODE_APP_API_RUL}/app/home`,
				{
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
				}
			);
			
			if (response.status === 200) {
				
				if (!response.data.posts) {
					return;
				}
				
				setGalleryImages(response.data.posts.map((post, index) => ({
					uid: post._id,
					caption: post.title,
					src: post.thumbNail.filePath,
				})))
			}
			
			
		} catch (ex) {
			console.error(ex)
		}
	}
	
	useEffect(() => {
		setTransition(true); // Start transition
		setTimeout(() => {
			// Apply a 500ms delay before fetching the images
			displayCarouselImages();
			displayGalleryImages();
		}, 500); // 500ms delay

		
		return () => {
			setCarouselImages([])
			setGalleryImages([]);
		}
	}, []);
	
	useEffect(() => {
		if (carouselImages.length > 0) {
			const preloadImages = carouselImages.map(image => {
				return new Promise((resolve) => {
					const img = new Image();
					img.src = image.url;
					img.onload = resolve;
				});
			});
			
			Promise.all(preloadImages).then(() => {
				// setImagesLoaded(true);
			})
		}
	}, [carouselImages]);
	
	return (
		<div>
			
			<div className={transition ? 'fade-out' : 'fade-in'}>
				<Carousel autoplay={true} autoplaySpeed={1850} >
					{isLoading ? (
						<div style={{ lineHeight: 0 }}>
							<Skeleton className="carousel-image" width="100%" />
						</div>
					) : (
						carouselImages.map((image) => (
							<div key={image.uid}>
								<LazyLoadImage
									alt={image.name}
									src={image.url}
									width="100%"
									className="carousel-image"
								/>
							</div>
						))
					)}
				</Carousel>
			</div>

			
			<div>
				<div className="home-top-title font-silkaMedium">We are a branding agency, bridging brands and consumers through purposeful design.</div>
			</div>
			
			<hr/>
			
			<div className="home-posts-section">
				<div className="o-latest-res">
					<span>Our latest projects</span>
				</div>
				{ galleryImages ? <PostList posts={galleryImages}/> : <SkeletonPostList itemsCount={4} /> }
			</div>
			
			<div className="more-works-section">
				<NavLink to='/works' onClick={() => window.scrollTo(0, 0)} className="text-black hover:underline active:underline flex justify-end" style={{width: 'max-content'}}>
					<div className="flex items-center" style={{gap: 16}}>
						<div>
							<span className="more-works-txt font-silkaMedium">More works</span>
						</div>
						<div>
							<ArrowUp />
						</div>
					</div>
				</NavLink>
			</div>
		
		</div>
	);
}


export default Home;
